import * as React from "react"
import Vimeo from '@u-wave/react-vimeo';
import Cookies from "universal-cookie"

const VimeoEmbed = ({ video_id }) => {

    const cookies = new Cookies()
    const cookie = cookies.get("rtv_prefs")

    const videoUrl = (video_id) => {
        return `https://vimeo.com/${video_id}`
    }

    const renderVideo = ({ video_id }) => {
        if (cookie && cookie['performance'] == true) {
            return <Vimeo
                video={video_id}
                responsive
                width="600"
                showByline={false}
                showPortrait={false}
                showTitle={false}
            />
        } else {
            return <div>
                <a href={videoUrl(video_id)} className="btn btn-muted">View on Vimeo</a>
                <p className="mt-2">Enable performance cookies to view this video on this page</p>
            </div>
        }
    }

    return (
        <div>{renderVideo({ video_id })}</div>
    )
}

export default VimeoEmbed